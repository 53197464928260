import React from "react";

import { AppLayout, OrdersGrid } from "@shared/salesTool";
import { ParentWrapper } from "@shared/salesTool/components.styles";

export default function SalesTool() {
  return (
    <AppLayout currentPath="orders">
      <ParentWrapper>
        <OrdersGrid title="Orders" />
      </ParentWrapper>
    </AppLayout>
  );
}
